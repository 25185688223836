import {useState, useEffect} from 'react';
import PageTitle from "../../layouts/PageTitle";
import {Col, Row} from "react-bootstrap";
import {BaseInput} from "../Defaults/BaseInput";
import {editUserAction, getPermissionUsers, usersDetailsActions} from "../../../_actions/user.Action"
import {useSelector, useDispatch} from "react-redux"
import {DefaultButton} from "../Defaults/DefaultButton";
import {Multiselect} from "multiselect-react-dropdown";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Select from 'react-select';
import chroma from 'chroma-js';

const EditUser = () => {
        const [email, setEmail] = useState("");
        const [mobile, setMobile] = useState("");
        const [rol, setRol] = useState([]);
        const [permission, setPermission] = useState([]);
        const [state, setState] = useState("");
        const {t} = useTranslation();
        ///data user
        const DetailsUser = useSelector((state) => state.DetailsUser)
        const {userDetails} = DetailsUser;

        //edit user
        const EditUser = useSelector((state) => state.EditUser)
        const {loading, error} = EditUser;

        const getPermission = useSelector((state) => state.getPermission)
        const {PermissionData} = getPermission;


        const dispatch = useDispatch();
        const {id} = useParams();


///get Permission
        useEffect(() => {
            dispatch(getPermissionUsers())

        }, [])
////get user Details

        useEffect(() => {
            dispatch(usersDetailsActions(id))
        }, []);


        useEffect(() => {
            setEmail(userDetails?.data?.email);
            setMobile(userDetails?.data?.mobile);
            setRol(userDetails?.data?.roles);
            setPermission(PermissionData?.data?.permissions)
        }, [userDetails]);

        let per = permission?.map((item) => item.value)


        const handelCrateUSer = (e) => {
            e.preventDefault()
            const data = {
                email: email,
                mobile: mobile,
                permissions: per,
                _method: "patch",
                roles_name: rol.map((item) => item.name),
            }
            dispatch(editUserAction(data, id))
        }

        const objectArray = [
            {name: "Admin"},
            {name: "stuff"},
        ];
        const handleSelect = (selected) => {
            setRol(selected)

        }
        const handlePermission = (selected) => {
            setPermission(selected)
        }
        const style = {
            chips: {
                background: "#2c254a",
                display: "flex",
                flexWrap: "wrap"
            },
            searchBox: {
                border: "0.5px solid #faf8f9",
                borderRadius: "10px",
                display: "flex",
                flexWrap: "wrap"
            },
            multiselectContainer: {
                color: "#000",
            },
            inputField: {
                margin: " 5px",
                textAlign: "right",
            },
            optionContainer: {
                border: " 1px solid",
            },
            option: {
                color: "#000",
                textAlign: "right",
                display: "flex",
                flexWrap: "wrap"
            },
            groupHeading: {}
        };

        const rolesPermissions = PermissionData?.data?.permissions.map((item) => {
            return {
                value: item.name,
                label: (
                    <>
                        <div className="">
                            {item.name_fa}
                        </div>
                    </>
                ),
            };
        });

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                border: '2px solid  #fff',
                color: state.isSelected ? 'red' : 'blue',
                padding: 20,
                display: "flex"
            }),
            control: () => ({
                // none of react-select's styles are passed to <Control />
                // width: 200, display:"flex"
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';

                return {...provided, opacity, transition};
            }
        }


        let userPer = userDetails?.data?.permissions.map((item) => item.name);
        console.log(PermissionData?.data?.permissions, "permissions")
        console.log(userDetails?.data?.permissions, "details")

        console.log(userPer, "tets")
        return (
            <div>
                <PageTitle activeMenu={t('EDIT_KEY')} prev={t('USERS_LIST_KEY')} motherMenu={t('DASHBOARD_KEY')}
                           dashUrl={""}
                           prevUrl={"users"}/>

                <div className="card p-5">
                    <form onSubmit={handelCrateUSer}>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={4}>
                                <BaseInput
                                    className="form-control "
                                    id="email"
                                    type="text"
                                    value={email}
                                    placeholder={t('EMAIL_KEY')}
                                    onChange={(e) => setEmail(e.target.value)}
                                    label={t('EMAIL_KEY')}
                                />
                                {error?.email && (<span className={"text-danger"}>{error.email}</span>)}
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                                <BaseInput
                                    className="form-control "
                                    id="mobile"
                                    type="text"
                                    value={mobile}
                                    placeholder={"9120000000"}
                                    onChange={(e) => setMobile(e.target.value)}
                                    label={t('MOBILE_KEY')}
                                />
                                {error?.mobile && (<span className={"text-danger"}>{error.mobile}</span>)}
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                                <label htmlFor="">{t('ROLE_KEY')}</label>
                                <Multiselect
                                    options={objectArray}
                                    displayValue="name"
                                    placeholder={t('ROLE_KEY')}
                                    onSelect={handleSelect}
                                    style={style}
                                    selectedValues={rol}
                                />
                                {error?.rolse && (<span className={"text-danger"}>{error?.roles_name}</span>)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <label className="mt-4">{t('PERMISSION_KEY')}</label>
                                <Select
                                    value={PermissionData?.data?.permissions.find((option) => console.log(option.name_fa) === userPer)}
                                    isMulti
                                    options={rolesPermissions}
                                    onChange={handlePermission}
                                    styles={customStyles}
                                    name="permission"
                                    instanceId="permission"
                                    className="basic-multi-select border"
                                    classNamePrefix="select"
                                />
                                {error?.rolse && (<span className={"text-danger"}>{error.rolse}</span>)}
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-center">
                            <DefaultButton
                                loading={loading}
                                type="submit"
                                className={`Approve_btn btn-primary mt-4 d-block `}
                                variant="contained"
                                color="primary"
                            >
                                {t('SAVE_KEY')}
                            </DefaultButton>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
;

export default EditUser;
