import React, {useState, useEffect,useMemo} from "react";
import {Link, useHistory} from "react-router-dom";
import {DefaultButton} from "../components/Defaults/DefaultButton";
import {BaseInput} from "../components/Defaults/BaseInput";
import {NumberInput} from "../components/Defaults/NumberInput";
import {isValidEmail} from "../components/Validation/validation";
import {
    userAuth,
    EmailAuth,
    VerifyUserAuth,
    VerifyEmailAuth,
} from "../../_actions/auth.actions";
import {ERROR} from "../../_helper/alerts";
import {useDispatch, useSelector} from "react-redux";
import Select from 'react-select'
import countryList from 'react-select-country-list'
// image
import logo from "../../images/logo.svg";

import {Tabs, Tab} from "react-bootstrap";

const Login = () => {
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileErr, setMobileErr] = useState("");
    const [verifyCode, setVerifyCode] = useState("");
    const [country_code, setCountryCode] = useState({value:"IR", label: "Iran"});
    const [isMobile, setIsMobile] = useState(false);
    const [isEmail, setIsEmail] = useState(false);

    const [passwordErr, setPasswordErr] = useState(false);
    const [page, setPage] = useState(false);
    const [emailErr, setEmailErr] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const {loading, error, success, successEmail} = auth;
    const options = useMemo(() => countryList().getData(), [])

    const submitHandler = (e) => {
        e.preventDefault();
    };
    const customStyles = {
        valueContainer:(provided, state) => ({
            ...provided,
           color: "#fff",
           
          }),
          input:(provided, state) => ({
            ...provided,
           color:"#000",
          }),
          singleValue:(provided, state) => ({
            ...provided,
           color:"#666",
           fontSize:12
          }),
          menuList:(provided, state) => ({
            ...provided,
           color: "#000",
           
          }),

       
      }
    ///validations
    useEffect(() => {
        if (isValidEmail(email) || email === "") {
            setEmailErr(false);
        } else {
            setEmailErr(true);
        }
    }, [email]);


    ///mobile
    const LoginHandleOtp = () => {
        const data = {
            mobile,
            country_code:country_code.value,
            with_send_code: 1,
        };
        dispatch(userAuth(data));
    };
    useEffect(() => {
        if (success) {
            setIsMobile(true);
        }
    }, [success]);

    const VerifyLoginHandleOtp = () => {
        const data = {
            mobile,
            country_code:country_code.value,
            verify_code: verifyCode,
            with_send_code: 0,
        };
        dispatch(VerifyUserAuth(data));
    };

    //email
    const LoginHandle = () => {
        const data = {
            email: email,
            with_send_code: 1,
        };
        dispatch(EmailAuth(data));
        if (successEmail) {
            setIsEmail(true);
        }
    };
    const verifyLoginHandle = () => {
        const data = {
            email: email,
            verify_code: verifyCode,
            with_send_code: 0,
        };
        dispatch(VerifyEmailAuth(data));
    };
    useEffect(() => {
        if (successEmail) {
            setIsEmail(true);
        }
    }, [successEmail]);

    ///validations
    useEffect(() => {
        if (isValidEmail(email) || email === "") {
            setEmailErr(false);
        } else {
            setEmailErr(true);
        }
    }, [email]);
  
    return (
        <div className="authincation h-100 p-meddle">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <Tabs
                            defaultActiveKey="mobile"
                            id="uncontrolled-tab-example"
                            className="mb-3 border-0"
                        >
                            <Tab eventKey="mobile" title="موبایل">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <div className="text-center mb-3">
                                                    <Link to="/">
                                                        <img src={logo} width="130" alt=""/>
                                                    </Link>
                                                </div>
                                                <form onSubmit={submitHandler}>
                                                    <div className="form-group">
                                                        <NumberInput
                                                            className="form-control text-right rtl_direction"
                                                            id="mobile"
                                                            type="text"
                                                            format="###########"
                                                            value={mobile}
                                                            placeholder="912-0000000"
                                                            onChange={(e) => setMobile(e.target.value)}
                                                            label={"موبایل"}
                                                        />
                                                        {error && (
                                                            <p className={"text-danger"}>{error?.mobile}</p>
                                                        )}
                                                        <div className="mb-3">
                                                            <Select placeholder={"انتخاب کشور"} value={country_code} onChange={(value)=>{setCountryCode(value)}} options={options} styles={customStyles}/>
                                                        </div>
                                                        {isMobile ? (
                                                            <BaseInput
                                                                className="form-control text-right rtl_direction"
                                                                id="verifyCode"
                                                                type="password"
                                                                value={verifyCode}
                                                                placeholder="رمز"
                                                                onChange={(e) => setVerifyCode(e.target.value)}
                                                                label={"رمز "}
                                                            />
                                                        ) : null}
                                                        {error && (
                                                            <p className={"text-danger"}>{error?.password}</p>
                                                        )}
                                                        {isMobile ? (
                                                            <div className="text-center">
                                                                <DefaultButton
                                                                    loading={loading}
                                                                    onClick={() => VerifyLoginHandleOtp()}
                                                                    type="submit"
                                                                    className="w-100 fs-12"
                                                                    variant="primary"
                                                                >
                                                                    ورود
                                                                </DefaultButton>
                                                            </div>
                                                        ) : (
                                                            <div className="text-center">
                                                                <DefaultButton
                                                                    // disabled={isInvalid}
                                                                    loading={loading}
                                                                    onClick={() => LoginHandleOtp()}
                                                                    type="submit"
                                                                    className="w-100 fs-12"
                                                                    variant="primary"
                                                                >
                                                                    بعدی
                                                                </DefaultButton>
                                                            </div>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="email" title="ایمیل">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <div className="text-center mb-3">
                                                    <Link to="/">
                                                        <img src={logo} width="130" alt=""/>
                                                    </Link>
                                                </div>
                                                <form onSubmit={submitHandler}>
                                                    <div className="form-group">
                                                        <BaseInput
                                                            className="form-control text-right rtl_direction"
                                                            id="email"
                                                            type="email"
                                                            value={email}
                                                            placeholder="info@gmail.com"
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            label={"ایمیل"}
                                                        />
                                                        {isEmail ? (
                                                            <>
                                                                <BaseInput
                                                                    className="form-control text-right rtl_direction"
                                                                    id="verifyCode"
                                                                    type="password"
                                                                    value={verifyCode}
                                                                    placeholder="رمز"
                                                                    onChange={(e) => setVerifyCode(e.target.value)}
                                                                    label={"رمز"}
                                                                />
                                                                {error && (
                                                                    <p className={"text-danger"}>{error?.password}</p>
                                                                )}
                                                            </>


                                                        ) : null}
                                                        {isEmail ? (
                                                            <div className="text-center">
                                                                <DefaultButton
                                                                    loading={loading}
                                                                    onClick={() => verifyLoginHandle()}
                                                                    type="submit"
                                                                    className="w-100 fs-12"
                                                                    variant="primary"
                                                                >
                                                                    ورود
                                                                </DefaultButton>
                                                            </div>
                                                        ) : (
                                                            <div className="text-center">
                                                                <DefaultButton
                                                                    // disabled={isInvalid}
                                                                    loading={loading}
                                                                    onClick={() => LoginHandle()}
                                                                    type="submit"
                                                                    className="w-100 fs-12"
                                                                    variant="primary"
                                                                >
                                                                    بعدی
                                                                </DefaultButton>
                                                            </div>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;
