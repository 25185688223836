import React, {useContext} from "react";

/// React router dom
import {BrowserRouter as Router, Switch, Route, useHistory} from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";
/// App

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import {ThemeContext} from "../context/ThemeContext";
//components
//category
import CreateCategory from "../jsx/components/Category/CreateCategory"
import AllCategories from "../jsx/components/Category/AllCategories"
import CategoryDetails from "../jsx/components/Category/CategoryDetails"
import EditCategory from "../jsx/components/Category/EditCategory"
import Products from "../jsx/components/Product/productList"
import ProductsDetails from "../jsx/components/Product/ProductDetails"
import EditProduct from "../jsx/components/Product/EditProducts"
import NewProducts from "../jsx/components/Product/NewProducts"
import {useSelector} from "react-redux";
//users
import UserList from "../jsx/components/users/UserList"
import CreateUser from "./components/users/CreateUser";
import UserDetails from "./components/users/UserDetails";
import EditUser from "./components/users/EditUser";
import Currency from "./components/Currency/Currency";
import CurrencyEdit from "./components/Currency/CurrencyEdit";
import OrderList from "../jsx/components/orders/OrderList"
import OrderDetails from "./components/orders/OrderDetails";
import Reviews from "./components/Reviews/Reviews"
import DetailsReviews from "./components/Reviews/DetailsReviews";
import EditReview from "./components/Reviews/EditReview";
import Transactions from "./components/Wallet/Transactions"
import ChargeWallet from "../jsx/components/Wallet/ChargeWallet"
import Coupon from "./components/coupon/Coupone";
import CreateCoupon from "./components/coupon/CreateCoupon";
import EditCoupon from "./components/coupon/EditCoupon";
import Pages from "./components/page/Pages";
import CreatePage from "./components/page/CreatePage";
import EditPage from "./components/page/EditPage";

const Markup = () => {
    const {menuToggle} = useContext(ThemeContext);
    const auth = useSelector((state) => state.auth)
    const {userInfo} = auth;

    const user = localStorage.getItem("token")
    let history = useHistory()
    if (!user) {
        history?.push("/page-login")
    }


    const routes = [
        /// Dashboard
        {url: "", component: Home},
        {url: "new-category", component: CreateCategory},
        {url: "categories", component: AllCategories},
        {url: "categories/:id", component: CategoryDetails},
        {url: "new-category/:id", component: EditCategory},
        {url: "users", component: UserList},
        {url: "new-user", component: CreateUser},
        {url: "users/:id", component: UserDetails},
        {url: "user-edit/:id", component: EditUser},

        {url: "products", component: Products},
        {url: "products/:id", component: ProductsDetails},
        {url: "new-products/:id", component: EditProduct},
        {url: "new-products", component: NewProducts},
        {url: "currency", component: Currency},
        {url: "currency/:id", component: CurrencyEdit},
        {url: "orders", component: OrderList},
        {url: "orders/:id", component: OrderDetails},
        {url: "reviews", component: Reviews},
        {url: "review/:id", component: DetailsReviews},
        {url: "review-edit/:id", component: EditReview},
        {url: "transactions", component: Transactions},
        {url: "charge-wallet", component: ChargeWallet},
        {url: "coupon", component: Coupon},
        {url: "new-coupon", component: CreateCoupon},
        {url: "edit-coupon/:id", component: EditCoupon},
        {url: "pages", component: Pages},
        {url: "new-page", component: CreatePage},
        {url: `edit-page/:id`, component: EditPage},

        /// Apps

        {url: "page-register", component: Registration},
        {url: "page-login", component: Login},
        {url: "page-forgot-password", component: ForgotPassword},
        {url: "page-error-400", component: Error400},
        {url: "page-error-403", component: Error403},
        {url: "page-error-404", component: Error404},
        {url: "page-error-500", component: Error500},
        {url: "page-error-503", component: Error503},
    ];
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    let pagePath = path.split("-").includes("page");

    return (
        <Router basename="/">
            <div
                id={`${!pagePath ? "main-wrapper" : ""}`}
                className={`${!pagePath ? "show" : "mh100vh"}  ${
                    menuToggle ? "menu-toggle" : ""
                }`}
            >
                {!pagePath && <Nav/>}

                <div className={`${!pagePath ? "content-body" : ""}`}>
                    <div
                        className={`${!pagePath ? "container-fluid" : ""}`}
                        style={{minHeight: window.screen.height - 60}}
                    >
                        <Switch>
                            {routes.map((data, i) => (
                                <Route
                                    key={i}
                                    exact
                                    path={`/${data.url}`}
                                    component={data.component}
                                />
                            ))}
                        </Switch>
                    </div>
                </div>
                {!pagePath && <Footer/>}
            </div>
            <Setting/>
        </Router>
    );
};

export default Markup;
