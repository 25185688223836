//user Auth Reduser
import {orderConst} from "../_constants/order-constant";

export const getAllOrderReducer = (state = {orders: []}, action) => {
    switch (action.type) {
        case orderConst.GET_ORDER_REQUEST :
            return {loading: true}
        case orderConst.GET_ORDER_SUCCESS:
            return {loading: false, orders: action.payload}
        case orderConst.GET_ORDER_FAIL :
            return {loading: false, error: action.payload}
        default:
            return state;
    }
};


export const getDetailsOrderReducer = (state = {details: []}, action) => {
    switch (action.type) {
        case orderConst.GET_DETAILS_ORDER_REQUEST :
            return {loading: true}
        case orderConst.GET_DETAILS_ORDER_SUCCESS:
            return {loading: false, details: action.payload}
        case orderConst.GET_DETAILS_ORDER_FAIL :
            return {loading: false, error: action.payload}
        default:
            return state;
    }
};
