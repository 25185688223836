import {Button, Modal, Spinner} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {getDetailsOrder} from "../../../_actions/order-actions"
import {Link, useParams} from "react-router-dom";
import {Row, Col} from "react-bootstrap"
import GiftModal from "./GiftModal";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const OrderDetails = () => {
        const orderDetails = useSelector((state) => state.orderDetails)
        const {loading, error, details} = orderDetails;
        console.log(details, "details")

        const dispatch = useDispatch()

        let {id} = useParams();
        let isValid = ValidPermissions(PermissionConst.ORDER_LIST)
        useEffect(() => {
            if (isValid) {
                dispatch(getDetailsOrder(id))
            }
        }, []);

        const {t} = useTranslation();
        return (
            <div className={"card p-5"}>
                {loading ? <Spinner animation="grow" variant="info"/> : (
                    <>
                        <Row>
                            <Col xs={12} md={4}>
                                <h3 className={"text-center"}>{t('USER_INFO_KEY')}</h3>
                                <ul className={"mt-4 border rounded-sm p-3"}>
                                    <li className={"d-flex justify-content-between align-items-center  mt-3"}>
                                        <label className={"text-dark "} htmlFor=""> {t('EMAIL_KEY')} : </label>
                                        {" "}
                                        <span>{details?.user?.email} </span>
                                    </li>
                                    <li className={"d-flex justify-content-between align-items-center  mt-3"}>
                                        <label className={"text-dark "} htmlFor="">{t('MOBILE_KEY')} : </label>
                                        {" "}
                                        <span>{details?.user?.mobile} </span>
                                    </li>
                                    <li className={"d-flex justify-content-between align-items-center  mt-3"}>
                                        <label className={"text-dark "} htmlFor=""> {t('DATE_SIGN_KEY')} : </label>
                                        {" "}
                                        <span>{details?.user?.created_at} </span>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} md={4}/>
                            <Col xs={12} md={4}>
                                <h3 className={"text-center"}>{t('ORDER_INFO_KEY')}</h3>
                                <ul className={"mt-4 border p-3 rounded-sm"}>
                                    <li className={"d-flex justify-content-between align-items-center  mt-3"}>
                                        <label className={"text-dark "} htmlFor="">{t('DATE_ORDERS')} : </label>
                                        {" "}
                                        <span>{details?.created_at} </span>
                                    </li>
                                    <li className={"d-flex justify-content-between align-items-center  mt-3"}>
                                        <label className={"text-dark "} htmlFor=""> {t('PAYMENT_TYPE')} : </label>
                                        {" "}
                                        <span>{details?.payment_type} </span>
                                    </li>
                                    <li className={"d-flex justify-content-between align-items-center  mt-3"}>
                                        <label className={"text-dark "} htmlFor=""> {t('PAIDS_KEY')} : </label>
                                        {" "}
                                        {details?.is_paid === 1 ? (
                                            <>

                                                        <span className="badge light badge-success ">
                                                          <i className="fa fa-check text-success mr-1"/>
                                                            <span className={'mr-2'}>
                                                              {t('PAID_KEY')}
                                                            </span>
                                                           </span>
                                            </>
                                        ) : details?.is_paid === 0 ? (
                                            <>
                                                    <span className="badge light badge-danger ">
                                                    <i className="fa fa-times text-danger mr-1"/>
                                                    <span className={'mr-2'}>
                                                   {t('NOY_PAID_KEY')}
                                                    </span>
                                                    </span>
                                            </>
                                        ) : null}
                                    </li>
                                    <li className={"d-flex justify-content-between align-items-center  mt-3"}>
                                        <label className={"text-dark "} htmlFor="">{t('STATUS_ORDERS_KEY')} : </label>
                                        {" "}
                                        {details?.is_resolved === 1 ? (
                                            <>
                                                        <span className="badge light badge-success ">
                                                          <i className="fa fa-check text-success mr-1"/>
                                                            <span className={'mr-2'}>
                                                               {t('DON_KEY')}
                                                            </span>
                                                           </span>
                                            </>
                                        ) : details?.is_resolved === 0 ? (
                                            <>
                                                    <span className="badge light badge-warning ">
                                                    <i className="fa fa-times text-warning mr-1"/>
                                                    <span className={'mr-2'}>
                                                   {t('UNDONE_KEY')}
                                                    </span>
                                                    </span>
                                            </>
                                        ) : null}
                                    </li>
                                    <li className={"d-flex justify-content-between align-items-center  mt-3"}>
                                        <label className={"text-dark "} htmlFor="">{t('DISCOUNT_key')} : </label>
                                        {" "}
                                        <span>{details?.discount_amount === null ? " 0" : details?.discount_amount} </span>
                                    </li>
                                    <li className={"d-flex justify-content-between align-items-center  mt-3"}>
                                        <label className={"text-dark "} htmlFor=""> {t('FEE_PAYABLE')} : </label>
                                        {" "}
                                        <span> {t('TOMAN_KEY')} {details?.payment_amount?.toLocaleString()} </span>
                                    </li>
                                    <li className={"d-flex justify-content-between align-items-center mt-3 border-top pt-3"}>
                                        <label className={"text-dark "} htmlFor=""> {t('PRICE_TOTAL_KEY')} : </label>
                                        {" "}
                                        <span> {t('TOMAN_KEY')} {details?.total_cost?.toLocaleString()} </span>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <div className="w-100 table-responsive">
                                <div id="patientTable_basic_table" className="dataTables_wrapper">
                                    <table
                                        id="example5"
                                        className=" dataTable no-footer w-100  table-striped  table-hover  mt-4"
                                        style={{minWidth: 845}}
                                        role="grid"
                                        aria-describedby="example5_info"
                                    >
                                        <thead>
                                        <tr role="row">
                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Patient ID: activate to sort column ascending"
                                            >
                                                {t('NAME_KEY')}

                                            </th>
                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Patient ID: activate to sort column ascending"
                                            >
                                                {t('PHOTO_KEY')}
                                            </th>
                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Patient ID: activate to sort column ascending"
                                            >
                                                {t('CURRENCY_NAME_KEY')}
                                            </th>

                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Patient Name: activate to sort column ascending"
                                            >
                                                {t('DATE_CREATE_KEY')}
                                            </th>

                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Disease: activate to sort column ascending"
                                            >
                                                {t('DATE_CREATE_KEY')}
                                            </th>

                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Disease: activate to sort column ascending"
                                            >
                                                {t('GIFT_KEY')}
                                            </th>

                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Doctor Assgined: activate to sort column ascending"
                                            >
                                                {t('PRICE_IN_DOLLARS')}
                                            </th>
                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Disease: activate to sort column ascending"
                                            >
                                                {t('NUMBER_OF_PRODUCTS_KEY')}
                                            </th>

                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Disease: activate to sort column ascending"
                                            >
                                                {t('PRICE_PRODUCT_KEY')}
                                            </th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {details?.products?.map((product, index) => (
                                            <>
                                                <tr key={index} role="row" className="odd">
                                                    <td className={'text-center'}>
                                                        {product?.name}
                                                    </td>

                                                    <td className={'text-center'}>
                                                        <img width={"100"} height={"100"} src={product.photo}
                                                             alt={product.name}/>
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {product?.currency_name === null ? "مشخص نیست" : product?.currency_name}
                                                    </td>

                                                    <td className={'text-center'}>
                                                        {product?.created_at}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {product?.gifts?.length}

                                                    </td>
                                                    <td className={'text-center'}>
                                                        {product?.gifts?.length === 0 ? "ندارد" : (
                                                            <GiftModal gifts={product?.gifts}>
                                                                <i className="fa fa-gift" aria-hidden="true"></i>
                                                            </GiftModal>
                                                        )}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {product?.min_price}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {product?.order_product_detail?.qty}
                                                    </td>

                                                    {/*<td className={'text-center'}>*/}
                                                    {/*    {product?.percentage_fee}*/}
                                                    {/*</td>*/}
                                                    <td className={'text-center'}>
                                                        {product?.order_product_detail?.product_price.toLocaleString()}
                                                    </td>

                                                </tr>
                                            </>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Row>
                    </>
                )}
            </div>
        );
    }
;
export default OrderDetails;
